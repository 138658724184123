.footer {
  margin-top: 2em;
  background-color: var(--theme-menu-background);
}

.footer ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 5em 0 5em 0;
  justify-content: space-around;
  align-items: stretch;
  list-style: none;
}

.footer ul li {
  margin: 0;
  padding: 0.15em 0.5em;
  display: flex;
  justify-content: center;
}

.footer ul li:last-child {
  border: none;
}

.footer ul li,
.footer ul li a {
  align-self: center;
  text-align: center;
  color: var(--theme-footer-link);
  font-size: 1em;
}
