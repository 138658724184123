.navigation {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1em; */
  background: var(--theme-menu-background);
  height: 3em;
}

.navigation a {
  color: var(--theme-menu-link);
  padding: 0.8em 0;
  border-right: 1px solid var(--theme-menu-separator);
  text-align: center;
  width: 100%;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

.navigation a:last-child {
  border: none;
}

.navigation a.active {
  background-color: var(--theme-menu-background-active);
  text-decoration: none;
  color: var(--theme-menu-link-active);
  font-weight: bold;
}

.navigation a:hover {
  background-color: var(--theme-menu-background-hover);
  color: var(--theme-menu-link-hover);
}
